import CommonAPIService from './CommonHandler';

export class TerminologyAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the terminology
  getTerminology(router) {
    const url = '/api/terminology';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getTerminologyByRank(rank_id, router) {
    const url = '/api/terminology/' + rank_id + '/rank';
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveTerminologyToTechniques(techniques, router) {
    const url = `/api/terminology/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getTerminologyByID(terminology_id, router) {
    const url = `/api/terminology/${terminology_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveTerminologyToRank(ranks, router) {
    const url = '/api/terminology/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addTerminology(terminology, router) {
    const url = '/api/terminology';
    return this.CommonAPIService.postCall(url, terminology, router);
  }

  updateTerminology(terminology_id, parms, router) {
    const url = `/api/terminology/${terminology_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  linkTerminologyToRank(terminology_id, rank_id, router) {
    const url = `/api/terminology/${terminology_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteTerminologyToRank(termnology_id, rank_id, router) {
    const url = `/api/terminology/${termnology_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  updateTerminologiesToRank(terminology_ids, rank_id, router) {
    const url = `/api/terminology/${terminology_ids}/${rank_id}/torank`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  deleteTerminology(terminology_id, router) {
    const url = `/api/terminology/${terminology_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  updateTestedOnTerminology(terminology_id, rank_id, tested, router) {
    const url = `/api/terminology/${terminology_id}/${rank_id}/${tested}/tested`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default TerminologyAPIService;
