<template>
  <div>
    <card v-if="spinning">  <!--IDK what this code does-->
      <div class="row">
        <div class="col-sm-12 text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </div>
    </card>
    <card>
      <div slot="header">
        <div class="row align-items-center">
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-12">
                <h4 class="card-title">Techniques Table</h4>
                <p class="card-category">Displays full list of the techniques in the system</p>
                <p class="card-category">
                  <el-button type="primary" @click="addBreak('create')" icon="">Add New Technique</el-button>
                  <el-button type="primary" @click="openModal('types')" icon="">Edit Technique Types</el-button>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row align-items-center">
              <div class="col-sm-8" style="padding-right: 0;"> <!-- Remove padding if needed -->
                <el-input v-model="search" placeholder="Search" @keyup.native.enter="filterTechniques"></el-input>
              </div>
              <div class="col-sm-4" style="padding-left: 5px;"> <!-- Minimal padding for spacing -->
                <el-button type="primary" @click="filterTechniques" icon="">Search</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="techTable" style="width: 100%">
        <el-table stripe :data="filteredTechniques" style="width: 100%" height="65vh" @sort-change="handleSortChange">
          <el-table-column prop="order" sortable="custom" label="Order" :min-width="150" align="center">
            <template v-slot="scope">
              <div class="row">
                <div class="col-sm-4">
                  {{ scope.row.order }}
                </div>
                <div class="col-sm-4">
                  <el-button type="text" icon="el-icon-arrow-up" @click="moveUp(scope.$index)" />
                </div>
                <div class="col-sm-4">
                  <el-button type="text" icon="el-icon-arrow-down" @click="moveDown(scope.$index)" />
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" sortable="custom" label="Name" width="250" align="center">
            <template v-slot="scope">
              <el-input v-model="scope.row.name" placeholder="Name" @change="updateTechnique(scope.row)"></el-input>
              <el-input v-model="scope.row.korean_name" placeholder="Translation" @change="updateTechnique(scope.row)"></el-input>
              <el-input v-model="scope.row.abbreviation" placeholder="Abbreviation" @change="updateTechnique(scope.row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Info" :min-width="150" align="center">
            <template v-slot="scope">
              <el-input v-model="scope.row.description" placeholder="Description" @change="updateTechnique(scope.row)"></el-input>
              <el-input v-model="scope.row.notes" placeholder="Notes" @change="updateTechnique(scope.row)"></el-input>
              <el-input v-model="scope.row.striking_surface" placeholder="Striking Surface" @change="updateTechnique(scope.row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Media" :min-width="150" align="center">
            <template v-slot="scope">
              <el-input v-model="scope.row.media" placeholder="Media URL" @change="updateTechnique(scope.row)"></el-input>
              <el-input placeholder="Width" v-model="scope.row.width" @change="updateTechnique(scope.row)"></el-input>
              <el-input placeholder="Depth" v-model="scope.row.depth" @change="updateTechnique(scope.row)"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="Technique Type" :min-width="150" align="center">
            <template v-slot="scope">
              <el-select v-model="scope.row.technique_type_id" placeholder="Select" style="width: 100%" @change="updateTechnique(scope.row)">
                <el-option
                  v-for="tpe in technique_types"
                  :key="tpe.value"
                  :label="tpe.name"
                  :value="tpe.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Breaking Details" :min-width="150" align="center">
            <template v-slot="scope">
              <div class="row">
                <div class="col-sm-4 d-flex align-items-center justify-content-center">
                  <el-checkbox v-model="scope.row.is_breaking" @change="updateTechnique(scope.row)">B</el-checkbox>
                </div>
                <div class="col-sm-8" v-if="scope.row.is_breaking">
                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center justify-content-center">
                      <el-tooltip effect="dark" content="A Minus">
                        <el-input type="number" min="0" v-model="scope.row.a_minus" placeholder="A Minus" @change="updateTechnique(scope.row)"></el-input>
                      </el-tooltip>
                    </div>
                    <div class="col-sm-12 d-flex align-items-center justify-content-center">
                      <el-tooltip effect="dark" content="Base Point Value">
                        <el-input type="number" min="0" v-model="scope.row.base_point_value" placeholder="Base Point Value" @change="updateTechnique(scope.row)"></el-input>
                      </el-tooltip>
                    </div>
                    <div class="col-sm-12 d-flex align-items-center justify-content-center">
                      <el-tooltip effect="dark" content="Board Multiplier">
                        <el-input type="number" min="0" v-model="scope.row.board_multiplier" placeholder="Board Multiplier" @change="updateTechnique(scope.row)"></el-input>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
                <div class="col-sm-8" v-else>
                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center justify-content-center">
                      <el-tooltip effect="dark" content="A Minus">
                        <el-input type="number" min="0" v-model="scope.row.a_minus" placeholder="A Minus" @change="updateTechnique(scope.row)" disabled></el-input>
                      </el-tooltip>
                    </div>
                    <div class="col-sm-12 d-flex align-items-center justify-content-center">
                      <el-tooltip effect="dark" content="Base Point Value">
                        <el-input type="number" min="0" v-model="scope.row.base_point_value" placeholder="Base Point Value" @change="updateTechnique(scope.row)" disabled></el-input>
                      </el-tooltip>
                    </div>
                    <div class="col-sm-12 d-flex align-items-center justify-content-center">
                      <el-tooltip effect="dark" content="Board Multiplier">
                        <el-input type="number" min="0" v-model="scope.row.board_multiplier" placeholder="Board Multiplier" @change="updateTechnique(scope.row)" disabled></el-input>
                      </el-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template v-slot="scope">
              <div class="row">
                <div class="col-sm-12">
                  <el-button type="danger" @click="deleteTechnique(scope.row.id)" icon="el-icon-delete"></el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </card>
    <!-- Dialog for button when pressed pretty sure-->
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="closeModal('error')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>

    <!--just added-->
    <el-dialog
      center
      title="Create New Technique"
      :visible.sync="modals.create">
      <div class="row">
        <div class="col-sm-4">
          <label>Name:</label>
          <el-input v-model="name" placeholder="Enter Technique Name"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Striking Surface:</label>
          <el-input v-model="striking_surface" placeholder="Striking Surface"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Translation:</label>
          <el-input v-model="korean_name" placeholder="Translation"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Abbreviation:</label>
          <el-input v-model="abbreviation" placeholder="Abbreviation"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Description:</label>
          <el-input v-model="description" placeholder="Description"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Notes:</label>
          <el-input v-model="notes" placeholder="Enter Notes"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Media:</label>
          <el-input v-model="media" placeholder="Enter Technique Media"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Width:</label>
          <el-input v-model="width" placeholder="Enter Width"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Depth:</label>
          <el-input v-model="depth" placeholder="Enter Depth"></el-input>
        </div>
        <div class="col-sm-4">
          <label>Technique Type:</label>
          <br>
          <el-select v-model="technique_type_id" placeholder="Select" style="width: 100%">
            <el-option
              v-for="tpe in technique_types"
              :key="tpe.value"
              :label="tpe.name"
              :value="tpe.value">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-8">
          <div class="row">
            <div class="col-sm-6">
              <br>
              <el-checkbox v-model="is_breaking"  size="large" border>Breaking</el-checkbox>
            </div>
            <div class="col-sm-6" v-if="is_breaking">
              <label>A Minus:</label>
              <el-input type="number" min=0 v-model="a_minus" placeholder="A Minus"></el-input>
              <label>Base Point Value:</label>
              <el-input type="number" min=0 v-model="base_point_value" placeholder="Base Point Value"></el-input>
              <label>Board Multiplier:</label>
              <el-input type="number" min=0 v-model="board_multiplier" placeholder="Board Multiplier"></el-input>
            </div>
          </div>
        </div>
        <div class="col-sm-12 text-center">
          <br>
          <span slot="footer" class="dialog-footer">
            <el-button type="success" @click="createTechnique('create')" icon="">OK</el-button>
            <el-button type="danger" @click="closeModal('create')" icon="">Cancel</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <div class="text-center">
        <span>{{ success_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
          <el-button type="success" @click="closeModal('success')" icon="">OK</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      center
      title="Technique Types"
      :visible.sync="modals.types">
      <el-button type="primary" @click="createTechniqueType" icon="">Add type</el-button>
      <el-table stripe :data="technique_types" style="width: 100%" @sort-change="handleSortChange">
        <el-table-column label="ID" :min-width="150" align="center">
          <template v-slot="scope">
            {{ scope.row.id }}
          </template>
        </el-table-column>
        <el-table-column label="Name" :min-width="150" align="center">
          <template v-slot="scope">
            <el-input v-model="scope.row.name" placeholder="Name" @change="updateTechniqueType(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column :min-width="100" align="center">
          <template v-slot="scope">
            <div class="row">
              <el-button type="danger" @click="deleteTechniqueType(scope.row.id)" icon="el-icon-delete"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="closeModal('types')" icon="">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {computed} from 'vue'
import { Dialog, Table, TableColumn, Input, Button, Select, Checkbox, Option, Tooltip, CheckboxButton  } from 'element-ui';
import TechniquesAPIService from "src/servicehandlers/TechniquesAPIService";
import BreakingTechniquesAPIService from "src/servicehandlers/BreakingTechniquesAPIService";

const techniqueAPIService = new TechniquesAPIService();
const breakingTechniquesAPIService = new BreakingTechniquesAPIService();

export default {
  name: "Techniques",
  components: {
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [CheckboxButton.name]: CheckboxButton,
  },

  data() {
    return {
      is_breaking: false,
      a_minus: null,
      base_point_value: null,
      board_multiplier: null,
      techniques: [],
      filteredTechniques: [],
      search: '',
      technique_types: [],
      width: null,
      depth: null,
      korean_name: null,
      abbreviation: null,
      striking_surface: null,
      notes: null,
      spinning: false,
      technique_type_id: null,
      error_txt: null,
      success_txt: null,
      name: null,
      technique_type_name: null,
      description: null,
      media: null,
      order: null,
      last_order: null,
      modals: {
        error: false,
        success: false,
        create: false,
        types: false,
      },
    }
  },
  mounted() {
    this.getTechniques();
    this.getTechniqueTypes();
  },
  methods: {
    getTechniqueTypes() {
      return techniqueAPIService.getTechniqueTypes(this.$router)
      .then((response) => {
        this.technique_types = []
        for (const resp of response) {
          resp.value = resp.id;
          resp.text = resp.name;
          this.technique_types.push(resp);
        }
        this.technique_types.sort((a, b) => a.id - b.id);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Error getting technique types! ' + error,
          timer: 1000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          showConfirmButton: false
        });
      });
    },
    onEnd() {
      // go through all the order numbers, align with the indexes
      const promises = [];
      for (let i = 0; i < this.techniques.length; i += 1) {
        const id = this.techniques[i].id;
        this.techniques[i].order = i + 1;
        promises.push(techniqueAPIService.updateTechnique(id, { order: i + 1 }, this.$router));
      }
      return Promise.all(promises)
      // now start updating both the old index and the new to the new values
      .then(() => {
        // const self = this;
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Error updating techniques! ' + error,
          timer: 1000,
          confirmButtonClass: 'btn btn-danger btn-fill',
          showConfirmButton: false
        });
      });
    },
    addBreak(name) {
      this.openModal(name);
    },
    getTechniques() {
      return techniqueAPIService.getTechnique(this.$router)
      .then((response) => {
        this.techniques = response;
        for (const tech of this.techniques) {
          tech.text = tech.name;
          tech.value = tech.id;
        }
        this.filteredTechniques=this.techniques
      })
      .catch((error) => {
        this.error_txt = 'Error getting Techniques: ' + error;
        this.openModal('error');
      });
    },
    async updateTechnique(technique_item) {
      let sync_technique = null;
      // insert the breaking technique
      if (technique_item.is_breaking) {
        const breaking_technique = {
          name: technique_item.name,
          description: technique_item.description,
          media: technique_item.media,
          notes: technique_item.notes,
          breaking_technique_type_id: technique_item.technique_type_id,
          is_breaking: technique_item.is_breaking,
          a_minus: technique_item.a_minus,
          base_point_value: technique_item.base_point_value,
          board_multiplier: technique_item.board_multiplier,
          single: true,
        };

        // if this is a breaking technique, update the breaking_techniques table
        try {
          if (technique_item.sync_to_breaking_techniques) {
            sync_technique = await breakingTechniquesAPIService.updateBreakingTechnique(technique_item.sync_to_breaking_techniques, breaking_technique, this.$router);
          } else {
            sync_technique = await breakingTechniquesAPIService.addBreakingTechnique(breaking_technique, this.$router);
            const parms = {
              sync_to_breaking_techniques: sync_technique.id,
            };
            technique_item.sync_to_breaking_techniques = sync_technique.id;
            const parms2 = {
              sync_to_techniques: technique_item.id
            };
            let updated_breaking = await breakingTechniquesAPIService.updateBreakingTechnique(sync_technique.id, parms2, this.$router);
          }
        } catch (e) {
          this.error_txt = 'Error creating breaking technique: ' + e;
          this.openModal('error');
          return;
        }
      }

      return techniqueAPIService.updateTechnique(technique_item.id, technique_item, this.$router)
      .then(() => {
        const self = this;
        this.success_txt = 'Technique Item update successful';
        //this.openModal('success');
        /*setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs*/
      })
      .catch((error) => {
        this.error_txt = 'Error updating Technique Item: ' + error;
        this.openModal('error');
      });
    },
    async updateTechniqueType(technique_type) {
      return techniqueAPIService.updateTechniqueType(technique_type.id, technique_type, this.$router)
        .then(() => {
          const self = this;
          this.success_txt = 'Technique Type update successful';
          /*this.openModal('success');
          setTimeout(function () {
            self.modals['success'] = false; // Use your variable name
          }, 1500); // Hide after 5 secs*/
        })
        .catch((error) => {
          this.error_txt = 'Error updating Technique Type: ' + error;
          this.openModal('error');
        });
    },
    async handleClose(done) {
      try {
        await this.$confirm('Are you sure you want to close this dialog?');
        done();
      } catch (e) {
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false;
    },
    moveUp(index) {
      if (index > 0) {
        const temp = this.techniques[index];
        this.techniques.splice(index, 1);
        this.techniques.splice(index - 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    moveDown(index) {
      if (index < this.techniques.length - 1) {
        const temp = this.techniques[index];
        this.techniques.splice(index, 1);
        this.techniques.splice(index + 1, 0, temp);
        this.updateOrder(temp)
      }
      this.onEnd();
    },
    updateOrder(item){
      for (let i = 0; i < this.techniques.length; i++){
        this.techniques[i].order = i+1
      }
      this.updateTechnique(item)
    },
    deleteTechnique(id) {
      return techniqueAPIService.deleteTechnique(id, this.$router)
      .then(() => {
        this.getTechniques();
        const self = this;
        this.success_txt = 'Delete Technique successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error deleting Technique: ' + error;
        this.openModal('error');
      });
    },
    deleteTechniqueType(id) {
      return techniqueAPIService.deleteTechniqueType(id, this.$router)
      .then(() => {
        this.getTechniqueTypes();
        const self = this;
        this.success_txt = 'Delete Technique Type successful';
        this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error deleting Technique: ' + error;
        this.openModal('error');
      });
    },
    handleSortChange({ column, prop, order}) {
      if (order === "ascending") {
        this.filteredTechniques.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));
      } else if (order === "descending") {
        this.filteredTechniques.sort((a, b) => (a[prop] < b[prop] ? 1 : -1));
      }
    },
    async createTechnique(modalName) {
      let order;
      if (this.last_order) {
        order = this.last_order;
        order += 1;
        this.last_order = order;
      } else {
        order = 1;
        this.last_order = 1;
      }

      let sync_technique = null;
      // insert the breaking technique
      if (this.is_breaking) {
        const breaking_technique = {
          name: this.name,
          description: this.description,
          media: this.media,
          notes: this.notes,
          breaking_technique_type_id: this.technique_type_id,
          is_breaking: this.is_breaking,
          a_minus: this.a_minus,
          base_point_value: this.base_point_value,
          board_multiplier: this.board_multiplier,
          single: true,
        };

        // if this is a breaking technique, update the breaking_techniques table
        try {
          sync_technique = await breakingTechniquesAPIService.addBreakingTechnique(breaking_technique, this.$router);
        } catch (e) {
          this.error_txt = 'Error creating breaking technique: ' + e;
          this.openModal('error');
          return;
        }
      }

      const technique_item = {
        name: this.name,
        description: this.description,
        media: this.media,
        order: order,
        notes: this.notes,
        abbreviation: this.abbreviation,
        korean_name: this.korean_name,
        technique_type_id: this.technique_type_id,
        striking_surface: this.striking_surface,
        sync_to_breaking_techniques: ((sync_technique) ? sync_technique.id : null),
        basic_motion: this.motion,
        width: this.width,
        depth: this.depth,
        is_breaking: this.is_breaking,
        a_minus: this.a_minus,
        base_point_value: this.base_point_value,
        board_multiplier: this.board_multiplier,
        single: true,
      };

      return techniqueAPIService.addTechnique(technique_item, this.$router)
      .then(async (response) => {
        if (this.is_breaking) {
          const parms = {
            sync_to_techniques: response.id
          };
          let updated_breaking = await breakingTechniquesAPIService.updateBreakingTechnique(sync_technique.id, parms, this.$router);
        }
        this.techniques.push(response);
        this.order = null;
        this.name = null;
        this.description = null;
        this.media = null;
        this.notes = null;
        this.abbreviation = null;
        this.korean_name = null;
        this.depth = null;
        this.width = null;
        this.is_breaking = false;
        this.a_minus = null;
        this.base_point_value = null;
        this.board_multiplier = null;
        this.closeModal(modalName);
        const self = this;
        this.success_txt = 'Add Technique successful';
        this.openModal('success');
        this.updateOrder(response)
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs
      })
      .catch((error) => {
        this.error_txt = 'Error creating technique: ' + error;
        this.openModal('error');
      });
    },
    async createTechniqueType() {
      const technique_type = {
        name: this.technique_type_name,
      };

      return techniqueAPIService.addTechniqueType(technique_type, this.$router)
      .then(async (response) => {
        this.technique_types.push(response);
        this.technique_type_name = null;
        const self = this;
        this.success_txt = 'Add Technique Type successful';
        /*this.openModal('success');
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 1500); // Hide after 5 secs*/
      })
      .catch((error) => {
        this.error_txt = 'Error creating technique: ' + error;
        this.openModal('error');
      });
    },
    filterTechniques(){
      this.filteredTechniques = this.techniques.filter(
        (data) =>
          !this.search ||
          data.name.toLowerCase().includes(this.search.toLowerCase())
      )
    }
  }
}
</script>


<style scoped>
el-input,el-select{
  width: 100%;
}
</style>



