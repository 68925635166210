import CommonAPIService from './CommonHandler';

export class SelfDefenseTechniquesAPIService {
  constructor() {
    this.search = '';
    this.CommonAPIService = new CommonAPIService();
  }

  // gets all the self_defense
  getSelfDefenseTechniques(router) {
    const url = '/api/self_defense';
    return this.CommonAPIService.getCall(url, '', router);
  }

  getSelfDefenseTechniquesByRank(rank_id, router) {
    const url = `/api/self_defense/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveSelfDefenseTechniquesToTechniques(techniques, router) {
    const url = `/api/self_defense/technique`;
    return this.CommonAPIService.postCall(url, {techniques: techniques}, router);
  }

  getSelfDefenseTechniquesByID(self_defense_id, router) {
    const url = `/api/self_defense/${self_defense_id}`;
    return this.CommonAPIService.getCall(url, '', router);
  }

  saveSelfDefenseTechniquesToRank(ranks, router) {
    const url = '/api/self_defense/rank';
    return this.CommonAPIService.postCall(url, {ranks: ranks}, router);
  }

  addSelfDefenseTechniques(self_defense, router) {
    const url = '/api/self_defense';
    return this.CommonAPIService.postCall(url, self_defense, router);
  }

  updateSelfDefenseTechniques(self_defense_id, parms, router) {
    const url = `/api/self_defense/${self_defense_id}`;
    return this.CommonAPIService.putCall(url, parms, router);
  }
  updateSelfDefenseToRank(self_defense_ids, rank_id, router) {
    const url = `/api/self_defense/${self_defense_ids}/${rank_id}/torank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  linkSelfDefenseTechniquesToRank(self_defense_id, rank_id, router) {
    const url = `/api/self_defense/${self_defense_id}/${rank_id}/rank`;
    return this.CommonAPIService.getCall(url, '', router);
  }
  deleteSelfDefenseTechniquesToRank(self_defense_id, rank_id, router) {
    const url = `/api/self_defense/${self_defense_id}/${rank_id}/rank`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }

  deleteSelfDefenseTechnique(self_defense_id, router) {
    const url = `/api/self_defense/${self_defense_id}`;
    return this.CommonAPIService.deleteCall(url, '', router);
  }
  updateTestedOnSelfDefenseTechnique(self_defense_technique_id, rank_id, tested, router) {
    const url = `/api/self_defense/${self_defense_technique_id}/${rank_id}/${tested}/tested`;
    return this.CommonAPIService.putCall(url, null, router);
  }
}

export default SelfDefenseTechniquesAPIService;
